
import BaseTable from "@/components/Base/BaseTable.vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";
import BaseTableRow from "@/components/Base/BaseTableRow.vue";
import ActionTableColumn from "@/components/Column/ActionTableColumn.vue";
import DropdownItem from "@/components/Dropdown/DropdownItem.vue";
import CrewOnBoardRow from "@/components/Planning/CrewOnBoardRow.vue";
import RenewCrewSignoffModal from "@/components/Planning/RenewCrewSignoffModal.vue";
import SwitchCrewModal from "@/components/Planning/SwitchCrewModal.vue";
import { CrewStatus, Readliness } from "@/enums";
import crewBoardHttp from "@/http/crewBoard";
import { helperMixin, infinityScrollTable, planningModal } from "@/mixins";
import { CrewBoardPaginate, CrewBoardPaginateFilter } from "@/models/crewBoard";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    fleetTabs: {
      type: Array,
      default: () => [],
    },

    fleetId: {
      required: false,
    },
  },

  components: {
    BaseTable,
    BaseTableRow,
    BaseTableColumn,
    ActionTableColumn,
    DropdownItem,
    SwitchCrewModal,
    RenewCrewSignoffModal,
    CrewOnBoardRow,
  },

  mixins: [
    helperMixin,
    planningModal,
    infinityScrollTable<CrewBoardPaginate, CrewBoardPaginateFilter>(
      crewBoardHttp()
    ),
  ],

  data() {
    return {
      CrewStatus,
      Readliness,
      showRenewCrewSignOffModal: false,
      showSwitchCrewModal: false,
    };
  },

  mounted() {
    if (this.fleetId) {
      this.filter.fleet_id = this.fleetId as string;
    }
  },

  onMounted() {
    if (this.fleetId) {
      this.filter.fleet_id = this.fleetId as string;
    }
  },

  watch: {
    filter: {
      deep: true,
      handler() {
        this.fetch(true);
      },
    },
  },
});
